import React from "react"
import styled from "styled-components"

function getKerstString() {
  return (
    <>
      In aanloop naar de Kerstdagen zijn wij ook dit jaar weer druk met het maken van onze <a href="https://www.shop.deklevenhorst.nl" target="_blank" rel="noopener">kerstboompjes</a>
      . Op dit moment kunnen wij u helaas niet ontvangen voor een reservering. Vanaf 2 april 2025 bent u weer van harte welkom.
    </>
  )
}

function getFromMarchString() {
  return (
    <>
      Vanaf 1 maart bent u weer van harte welkom bij onze arrangementen.
    </>
  )
}

// Use this variable to enable or disable the message
const kerstEnabled = false;

export function Kerst({data}) {
  if (kerstEnabled) {
    return (
      <StyledContent>
        {data && data.content ? data.content : getKerstString()}
      </StyledContent>
      
      // <StyledContent>
      //   {data && data.content ? data.content : getFromMarchString()}
      // </StyledContent>
    )
  } else {
    return (
      <></>
    )
  }
}

const StyledContent = styled.div`
  border: 2px solid rgb(160, 206, 78);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(160, 206, 78, 0.2);
`

export const KerstBlock = {
  label: "Kerst",
  name: "kerst",
  key: "test",
  defaultItem: {
    text: "",
  },
  fields: [
    { name: "content", label: "Text", component: "text" },
  ],
}
